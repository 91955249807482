import React from 'react';
import Barcode from 'react-barcode';

const BarcodeComponent = ({ value }) => (
  <Barcode
    value={value}
    height={12}
    width={1}
    displayValue={true}
    fontSize={10}
    margin={0}
  />
);

export default BarcodeComponent;